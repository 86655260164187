<template>
  <div class="stats">
    <div>
      <b-container class="topSec">
        <b-row class="mobileRowSection">
          <b-col>
            <b-row class="topSecrow statsTitle">
              <b-col>
                <h3>
                  International Series Rankings
                </h3>
              </b-col>
              <b-col>
                <b-img
                  class="logo"
                  src="https://assets.asiantour.com/asian-tour/2024/02/IS.png"
                ></b-img>
              </b-col>
            </b-row>
            <b-row class="topSecrow">
              <b-col>
                <h4 class="titleDate">
                  {{ data.date_valid }}
                </h4>
              </b-col>
            </b-row>
            <b-row class="topSecrow">
              <b-col>
                <h4 class="titleDate smallText">
                  {{ data.last_tournament}}
                </h4> 
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="topSecrow select">
          <b-col lg='6' md='6' cols='12'>
              <b-form-select v-model="id" class="schedule-dropdown" v-on:change="changeYear(id)">
                <b-form-select-option 
                  class="opt-arrow"
                  v-for="(value,
                  index) in tm_params.seasons.seasons_entry.slice().reverse()"
                  :key="index"
                  :value="value.code"
                >{{value.desc}}</b-form-select-option>
              </b-form-select>
          </b-col>
          <b-col lg='6' md='6' cols='12'>
            <!-- <b-dropdown v-if="stats.stats === ''" :text="data.short_name">
              <b-dropdown-item>No Stats</b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              v-else-if="Array.isArray(stats.ooms.ooms_entry)"
              :text="data.short_name"
              class="arrowD"
            >
              <b-dropdown-item
                v-for="(ooms, index) in stats.ooms.ooms_entry"
                :key="index + Math.random()"
                v-on:click="pickStat"
                :title="'-ooms-oom-' + ooms.code"
                >{{ ooms.short_name }}</b-dropdown-item
              >
              <b-dropdown-item
                v-on:click="pickStat"
                v-for="(stats, index) in stats.stats.stats_entry"
                :key="index + Math.random()"
                :title="'-stats-stats-' + stats.code"
                >{{ stats.short_name }}</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown v-else :text="data.short_name" class="arrowD">
              <b-dropdown-item
                v-on:click="pickStat"
                :title="'-ooms-oom-' + stats.ooms.ooms_entry.code"
                >{{ stats.ooms.ooms_entry.full_name }}</b-dropdown-item
              >
              <b-dropdown-item
                v-on:click="pickStat"
                v-for="(stats, index) in stats.stats.stats_entry"
                :key="index + Math.random()"
                :title="'-stats-stats-' + stats.code"
                >{{ stats.short_name }}</b-dropdown-item
              >
            </b-dropdown> -->
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="NoPad">
      <b-card title="Card Title" no-body>
        <StatsTable v-if="stats.length === 0" :id="id" :config="config"/>
        <StatsTable v-else :id="id" :stat="stat" :data="data" :config="config"/>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import StatsTable from "@/components/statsTable.vue";

export default {
  name: "stats",
  props:['config'],
  components: {
    StatsTable
  },
  data() {
    return {
      tm_params: [],
      stats: [],
      id: '2024',
      stat: "-ooms-oom-IS",
      data: [],
    };
  },
  methods: {
    seasonDes(id){
      var year = this.tm_params.seasons.seasons_entry.filter((seas) => seas.code === id)
      return year[0].desc
    },
    changeYear: function() {
      var id = this.id
      return (
        (this.id = id),
        apiCall
          .tmStats(this.id)
          .then(({ data }) => {
            this.stats = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          }),
        apiCall
          .stat(this.id, this.stat)
          .then(({ data }) => {
            this.data = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          })
      );
    },
    pickStat: function(event) {
      var stat = event.target.getAttribute("title");
      return (
        (this.stat = stat),
        apiCall
          .stat(this.id, this.stat)
          .then(({ data }) => {
            this.data = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          })
      );
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.tmParamsBase();
      this.tm_params = first_call.data;
      this.season = first_call.data.tm_params.season_code;
      this.DEFIMAGESAMS3 = first_call.data.tm_params.DEFIMAGESAMS3;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.tmStats(this.id);
      this.stats = second_call.data;
      this.loading = false;
      // Third call, await for call to return
      let third_call = await apiCall.stat(this.id, this.stat);
      this.data = third_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
}
}
</script>

<style scoped>
::v-deep .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none!important;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  /* border-left: 0.3em solid transparent; */
  float: right;
  margin-top: 10px;
  background: #fff url(https://assets.asiantour.com/asian-tour/2023/12/Icon_awesome-chevron-down.png) right 0.75rem center / 12px 8px no-repeat;
}
::v-deep .btn-secondary {
  color: #7E7E7E;
  background-color: #ffffff;
  border-color: #ebebeb;
  border-radius: 0px;
  font-size: 16px;
  width: 365px;
  height: 43px;
  border: 0px solid;
  text-align: left;
  margin-bottom: 30px;
  /* padding-left: 25px!important; */
  background: #fff url(https://assets.asiantour.com/asian-tour/2023/12/Icon_awesome-chevron-down.png) right 0.75rem center / 12px 8px no-repeat;
}
.NoPad {
  padding: 0px;
}
.statsTitle {
  font-size: 20px!important;
  font-weight: 500;
  color: #0A3F7F;
}
.custom-select {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0px solid #c7c7cc;
  height: 43px;
  font-size: 16px;
  width: 100%;
  color: #aaa !important;
  background: #fff url(https://assets.asiantour.com/asian-tour/2023/12/Icon_awesome-chevron-down.png) right 0.75rem center/12px 8px no-repeat;
}
::v-deep th:first-child {
  /* padding-left: 21.5em; */
}
::v-deep td:first-child {
  /* padding-left: 21.5em; */
}
::v-deep th:last-child {
  /* padding-right: 21.5em; */
}
::v-deep td:last-child {
  /* padding-right: 21.5em; */
}
::v-deep .row.topSecrow.select {
  margin-top: 60px;
  margin-bottom: 0px;
}
::v-deep a.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529!important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
::v-deep .arrowD > button::after {
  float: right;
  position: relative;
  top: 10px;
}
.smallText {
  font-size: 13px!important;
}
.titleDate {
  /* text-transform: capitalize; */
  font-weight: bolder;
  font-size: 18px;
  color: #0A3F7F;
  font-weight: 500;
}
.stats {
  background-color: #f1f1f1;
}
::v-deep .btn {
  background-color: #fff;
  border-radius: 0px;
  color: #aaa !important;
  text-align: left;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0px solid #c7c7cc;
  height: 43px;
  font-size: 16px;
  width: 100%;
}
.topSec {
  padding-top: 5%;
  padding-bottom: 2%;
  padding-left: 0px;
  padding-right: 0px;
}

.title {
  color: #0A3F7F;
  font-weight: 500;
}
.select {
  text-align: center;
}
.card {
  background-color: #F5F5F5;
  border: 0;
}
.dropdown {
  width: 100%;
}
.topSecrow {
  /* padding-top: 2%; */
}
.logo {
  width: 120px;
  float: right;
}
@media only screen and (max-width: 990px) {
  .NoPad {
    padding: 15px;
  }
  ::v-deep .row.topSecrow.select {
    padding-left: 30px;
    padding-right: 30px;
  }
  .row.mobileTopSection {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 500px) {
  .row.topSecrow.select {
    margin-top: 60px;
    margin-bottom: 0;
  }
  .row.mobileRowSection {
    padding-left: 47px;
    padding-right: 47px;
  }
  ::v-deep thead > tr {
    height: auto!important;
  } 
  ::v-deep .table th, .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 0;
  }
  ::v-deep tr:nth-child(odd) {
    height: 65px;
  }
  ::v-deep tr:nth-child(even) {
    height: 65px;
  }
}
@media only screen and (max-width: 480px) {
  .flag{
    width: 100%;
  }
  .dropdown{
    padding-bottom: 10px;
  }
}
</style>
